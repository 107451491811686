import React from 'react'
import classNames from 'classnames'
/*
 * PEDI STOOL
 */
// regular texture
import Pedistoolcappuccino from '../images/pedi-stool/pedistool-cappuccino.png'
import Pedistoolivory from '../images/pedi-stool/pedistool-ivory.png'
import Pedistoolfossilgrey from '../images/pedi-stool/pedistool-fossilgrey.png'
import Pedistoolburgundy from '../images/pedi-stool/pedistool-burgundy.png'
import Pedistooldarkchocolate from '../images/pedi-stool/pedistool-darkchocolate.png'
import Pedistoolblack from '../images/pedi-stool/pedistool-black.png'
// diamond texture
import Pedistoolcappuccinodiamond from '../images/pedi-stool/pedistool-cappuccino-diamond.png'
import Pedistoolivorydiamond from '../images/pedi-stool/pedistool-ivory-diamond.png'
import Pedistoolfossilgreydiamond from '../images/pedi-stool/pedistool-fossilgrey-diamond.png'
import Pedistoolburgundydiamond from '../images/pedi-stool/pedistool-burgundy-diamond.png'
import Pedistooldarkchocolatediamond from '../images/pedi-stool/pedistool-darkchocolate-diamond.png'
import Pedistoolblackdiamond from '../images/pedi-stool/pedistool-black-diamond.png'

/*
 * TECH CHAIR
 */
// regular texture
import Techchaircappuccino from '../images/tech-chair/techchair-cappuccino.png'
import Techchairivory from '../images/tech-chair/techchair-ivory.png'
import Techchairfossilgrey from '../images/tech-chair/techchair-fossilgrey.png'
import Techchairburgundy from '../images/tech-chair/techchair-burgundy.png'
import Techchairdarkchocolate from '../images/tech-chair/techchair-darkchocolate.png'
import Techchairblack from '../images/tech-chair/techchair-black.png'
// diamond texture
import Techchaircappuccinodiamond from '../images/tech-chair/techchair-cappuccino-diamond.png'
import Techchairivorydiamond from '../images/tech-chair/techchair-ivory-diamond.png'
import Techchairfossilgreydiamond from '../images/tech-chair/techchair-fossilgrey-diamond.png'
import Techchairburgundydiamond from '../images/tech-chair/techchair-burgundy-diamond.png'
import Techchairdarkchocolatediamond from '../images/tech-chair/techchair-darkchocolate-diamond.png'
import Techchairblackdiamond from '../images/tech-chair/techchair-black-diamond.png'

/*
 * MANI STOOL
 */
import Manistoolcappuccino from '../images/mani-stool/manistool-cappuccino.png'
import Manistoolivory from '../images/mani-stool/manistool-ivory.png'
import Manistoolfossilgrey from '../images/mani-stool/manistool-fossilgrey.png'
import Manistoolburgundy from '../images/mani-stool/manistool-burgundy.png'
import Manistooldarkchocolate from '../images/mani-stool/manistool-darkchocolate.png'
import Manistoolblack from '../images/mani-stool/manistool-black.png'
/*
 * CUSTOMER CHAIR
 */
import Customerchaircappuccino from '../images/customer-chair/customerchair-cappuccino.png'
import Customerchairivory from '../images/customer-chair/customerchair-ivory.png'
import Customerchairfossilgrey from '../images/customer-chair/customerchair-fossilgrey.png'
import Customerchairburgundy from '../images/customer-chair/customerchair-burgundy.png'
import Customerchairdarkchocolate from '../images/customer-chair/customerchair-darkchocolate.png'
import Customerchairblack from '../images/customer-chair/customerchair-black.png'

const chairColors = ['black', 'fossilgrey', 'cappuccino', 'ivory', 'burgundy', 'darkchocolate']
const textures = ['regular', 'diamond']

const chairs = {
  pedistool: {
    cappuccino_regular: Pedistoolcappuccino,
    ivory_regular: Pedistoolivory,
    fossilgrey_regular: Pedistoolfossilgrey,
    burgundy_regular: Pedistoolburgundy,
    darkchocolate_regular: Pedistooldarkchocolate,
    black_regular: Pedistoolblack,

    cappuccino_diamond: Pedistoolcappuccinodiamond,
    ivory_diamond: Pedistoolivorydiamond,
    fossilgrey_diamond: Pedistoolfossilgreydiamond,
    burgundy_diamond: Pedistoolburgundydiamond,
    darkchocolate_diamond: Pedistooldarkchocolatediamond,
    black_diamond: Pedistoolblackdiamond
  },
  techchair: {
    cappuccino_regular: Techchaircappuccino,
    ivory_regular: Techchairivory,
    fossilgrey_regular: Techchairfossilgrey,
    burgundy_regular: Techchairburgundy,
    darkchocolate_regular: Techchairdarkchocolate,
    black_regular: Techchairblack,

    cappuccino_diamond: Techchaircappuccinodiamond,
    ivory_diamond: Techchairivorydiamond,
    fossilgrey_diamond: Techchairfossilgreydiamond,
    burgundy_diamond: Techchairburgundydiamond,
    darkchocolate_diamond: Techchairdarkchocolatediamond,
    black_diamond: Techchairblackdiamond
  },
  manistool: {
    cappuccino_regular: Manistoolcappuccino,
    ivory_regular: Manistoolivory,
    fossilgrey_regular: Manistoolfossilgrey,
    burgundy_regular: Manistoolburgundy,
    darkchocolate_regular: Manistooldarkchocolate,
    black_regular: Manistoolblack
  },
  customerchair: {
    cappuccino_regular: Customerchaircappuccino,
    ivory_regular: Customerchairivory,
    fossilgrey_regular: Customerchairfossilgrey,
    burgundy_regular: Customerchairburgundy,
    darkchocolate_regular: Customerchairdarkchocolate,
    black_regular: Customerchairblack
  }
}

const ChairAccessories = ({ model, des, defaultChairColor, defaultTexture, price, name }) => {
  const [currentChairColor, setCurrentChairColor] = React.useState(defaultChairColor)
  const [currentTexture, setCurrentTexture] = React.useState(defaultTexture)
  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="col-lg-4 d-flex flex-column align-items-center justify-content-center animated fadeInLeft slow py-4">
            <img
              src={chairs[`${model}`][`${currentChairColor}_${currentTexture}`]}
              alt={model}
              className={classNames('img-fluid', `${model}-img`)}
            />
            <h6 className="text-secondary my-4">Digital Coloring May Vary</h6>
          </div>
          <div className="col-lg-5 d-flex flex-column justify-content-center animated fadeInRight slow">
            <div className="mb-2 d-flex align-items-center flex-wrap">
              <h5 className="mb-0 mr-4">
                <strong>Color</strong>
              </h5>
              {chairColors.map(chairColor => {
                return (
                  <button
                    key={chairColor}
                    type="button"
                    aria-label="chair color button"
                    onClick={() => setCurrentChairColor(chairColor)}
                    className={classNames(
                      'color-btn',
                      `${chairColor}`,
                      'rounded-circle mr-2 mb-1',
                      {
                        'animated infinite pulse': currentChairColor === chairColor
                      }
                    )}
                  />
                )
              })}
            </div>

            {(model === 'pedistool' || model === 'techchair') && (
              <div className="my-1 d-flex  align-items-center flex-wrap">
                <h5 className="mb-0 mr-2">
                  <strong>Stitch</strong>
                </h5>
                {textures.map(texture => {
                  return (
                    <button
                      key={texture}
                      type="button"
                      className={classNames('action-button rounded-pill mx-1', {
                        'animated infinite pulse': currentTexture === texture
                      })}
                      onClick={() => setCurrentTexture(texture)}
                    >
                      {texture}
                    </button>
                  )
                })}
              </div>
            )}
          </div>
          <div className="col-lg-3 d-flex mt-4 flex-column justify-content-center animated fadeInRight slow">
            <h2 className="text-uppercase">
              {name} {currentChairColor} {currentTexture}
            </h2>
            {model !== 'techchair' && <p>$ {currentTexture === 'diamond' ? '235' : price}</p>}
            {model === 'techchair' && <p>$ {currentTexture === 'diamond' ? '245' : price}</p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default ChairAccessories
