import React from 'react'
import { Link } from 'gatsby'

import VSportLogo from '../images/logos/vsport-logo.png'
import VIPLogo from '../images/logos/vip-logo.png'
import NovoLogo from '../images/logos/novo-logo.png'
import MagnaLogo from '../images/logos/magna-logo.png'
import TergoLogo from '../images/logos/tergo-logo.png'

const ChairModelNavBar = () => (
  <>
    <div className="scroll-nav d-flex justify-content-around bg-white shadow-sm px-2 py-4">
      <Link
        to="/pedicure-spa-chairs/magna"
        className="model-nav-link p-2 mr-2 rounded shadow-sm"
        activeClassName="active-model-chair-link"
      >
        <img src={MagnaLogo} className="model-logo-img px-2" alt="pedicure spa chair logo" />
      </Link>
      <Link
        to="/pedicure-spa-chairs/vip"
        className="model-nav-link p-2 mr-2 rounded shadow-sm"
        activeClassName="active-model-chair-link"
      >
        <img src={VIPLogo} className="model-logo-img px-2" alt="pedicure spa chair logo" />
      </Link>
      <Link
        to="/pedicure-spa-chairs/vsport"
        className="model-nav-link p-2 mr-2 rounded shadow-sm"
        activeClassName="active-model-chair-link"
      >
        <img src={VSportLogo} className="model-logo-img px-2" alt="pedicure spa chair logo" />
      </Link>
      <Link
        to="/pedicure-spa-chairs/tergo"
        className="model-nav-link p-2 mr-2 rounded shadow-sm"
        activeClassName="active-model-chair-link"
      >
        <img src={TergoLogo} className="model-logo-img px-2" alt="pedicure spa chair logo" />
      </Link>
      <Link
        to="/pedicure-spa-chairs/novo"
        className="model-nav-link p-2 mr-2 rounded shadow-sm"
        activeClassName="active-model-chair-link"
      >
        <img src={NovoLogo} className="model-logo-img px-2" alt="pedicure spa chair logo" />
      </Link>
    </div>
  </>
)

export default ChairModelNavBar
