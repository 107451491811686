import React from 'react'
import YouTubePlayer from 'react-player/lib/players/YouTube'

const ResEmbeddedYoutube = ({ url, autoPlay }) => (
  <div className="player-wrapper">
    <YouTubePlayer
      className="react-player"
      url={url}
      playing={autoPlay}
      controls
      width="100%"
      height="100%"
      // Other ReactPlayer props will work here
    />
  </div>
)

export default ResEmbeddedYoutube
