import React from 'react'
import classNames from 'classnames'
import { Icon } from 'semantic-ui-react'
import ResEmbeddedYoutube from './ResEmbeddedYoutube'

const features = [
  {
    name: 'all',
    videoUrl: 'https://www.youtube.com/watch?v=UgweYkPh1jU?rel=0',
    shortText: 'Play All'
  },
  {
    name: 'jet-liner',
    title: 'Patented 100% Disposable JET-LINER™',
    videoUrl: 'https://youtu.be/L85vXpCD3FQ?rel=0',
    des: 'some feature description',
    shortText: 'JET-LINER™'
  },
  {
    name: 'infinity-overflow',
    title: 'Patented Infinity Overflow™',
    videoUrl: 'https://youtu.be/vAIOHsPH83E?rel=0',
    des: 'some feature description',
    shortText: 'Infinity Overflow™'
  },
  {
    name: 'easy-drain',
    title: 'Patented EasyDrain™',
    videoUrl: 'https://youtu.be/1Uac76gekk4?rel=0',
    des: 'some feature description',
    shortText: 'EasyDrain™'
  },
  {
    name: 'armrest',
    title: 'Durable Armrest & Tray Table',
    videoUrl: 'https://youtu.be/pYUKEkaHyDY?rel=0',
    des: 'some feature description',
    shortText: 'Armrest'
  },
  {
    name: 'footrest',
    title: 'Adjustable Footrests',
    videoUrl: 'https://youtu.be/R-uid8VJmtU?rel=0',
    des: 'some feature description',
    shortText: 'Footrest'
  },
  {
    name: 'showerhead',
    title: 'Dual Function Showerhead',
    videoUrl: 'https://youtu.be/4MpNqQuDNrA?rel=0',
    des: 'some feature description',
    shortText: 'Showerhead'
  },
  {
    name: 'basin',
    title: '7-Colored LED Basin Light',
    videoUrl: 'https://youtu.be/ed0VMcwewrI?rel=0',
    des: 'some feature description',
    shortText: 'Basin'
  }
]

const ChairFeatures = () => {
  const [activeFeature, setActiveFeature] = React.useState('all')

  return (
    <div className="container pt-5 pb-2 px-5">
      <div className="row pb-5">
        <h1 className="text-center w-100">ADVANCED & PATENTED TECHNOLOGIES</h1>
      </div>
      {features.map(feature => {
        return (
          <div key={feature.shortText}>
            {feature.name !== 'all' ? (
              <div
                className={classNames('video-card row px-0', {
                  active: feature.name === activeFeature
                })}
              >
                <div className="col-md-4 px-4 pt-4 animated fadeInUp text-center">
                  <h2>{feature.title}</h2>
                  <p>{feature.des}</p>
                </div>
                <div className="col-md-8 py-4 animated fadeInUp">
                  <ResEmbeddedYoutube url={feature.videoUrl} width="100%" height="100%" />
                </div>
              </div>
            ) : (
              <div
                className={classNames('video-card row px-0 justify-content-center', {
                  active: feature.name === activeFeature
                })}
              >
                <div className="col-10 py-4 animated fadeInUp">
                  <ResEmbeddedYoutube url={feature.videoUrl} width="100%" height="100%" />
                </div>
              </div>
            )}
          </div>
        )
      })}
      <div className="scroll-nav feature-nav rounded d-flex align-items-center justify-content-around py-4 px-2 bg-white">
        {features.map(feature => {
          return (
            <div
              role="button"
              key={feature.name}
              tabIndex="0"
              className={classNames('feature-selection-btn py-2 px-4 mr-2', {
                active: feature.name === activeFeature
              })}
              onClick={() => {
                setActiveFeature(feature.name)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  setActiveFeature(feature.name)
                }
              }}
            >
              {feature.name === 'all' && <Icon name="play" />}
              {feature.name !== 'all' && <h4>{feature.shortText}</h4>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ChairFeatures
