import React from 'react'
import classNames from 'classnames'

// Bowl
import rosegoldBowl from '../images/bowl-images/rosegold.jpg'
import goldBowl from '../images/bowl-images/gold.jpg'
import pearlBowl from '../images/bowl-images/pearl.jpg'
import mochaBowl from '../images/bowl-images/mocha.jpg'
import graphiteBowl from '../images/bowl-images/graphite.jpg'

// Novo
import Novodiagonalblackrosegold from '../images/spa-chairs/novo/diagonal/novo-black-rosegold.png'
import Novodiagonalblackgold from '../images/spa-chairs/novo/diagonal/novo-black-gold.png'
import Novodiagonalblackpearl from '../images/spa-chairs/novo/diagonal/novo-black-pearl.png'
import Novodiagonalblackgraphite from '../images/spa-chairs/novo/diagonal/novo-black-graphite.png'
import Novodiagonalblackmocha from '../images/spa-chairs/novo/diagonal/novo-black-mocha.png'

import Novodiagonalfossilgreyrosegold from '../images/spa-chairs/novo/diagonal/novo-fossilgrey-rosegold.png'
import Novodiagonalfossilgreygold from '../images/spa-chairs/novo/diagonal/novo-fossilgrey-gold.png'
import Novodiagonalfossilgreypearl from '../images/spa-chairs/novo/diagonal/novo-fossilgrey-pearl.png'
import Novodiagonalfossilgreygraphite from '../images/spa-chairs/novo/diagonal/novo-fossilgrey-graphite.png'
import Novodiagonalfossilgreymocha from '../images/spa-chairs/novo/diagonal/novo-fossilgrey-mocha.png'

import Novodiagonalcappuccinorosegold from '../images/spa-chairs/novo/diagonal/novo-cappuccino-rosegold.png'
import Novodiagonalcappuccinogold from '../images/spa-chairs/novo/diagonal/novo-cappuccino-gold.png'
import Novodiagonalcappuccinopearl from '../images/spa-chairs/novo/diagonal/novo-cappuccino-pearl.png'
import Novodiagonalcappuccinographite from '../images/spa-chairs/novo/diagonal/novo-cappuccino-graphite.png'
import Novodiagonalcappuccinomocha from '../images/spa-chairs/novo/diagonal/novo-cappuccino-mocha.png'

import Novodiagonalivoryrosegold from '../images/spa-chairs/novo/diagonal/novo-ivory-rosegold.png'
import Novodiagonalivorygold from '../images/spa-chairs/novo/diagonal/novo-ivory-gold.png'
import Novodiagonalivorypearl from '../images/spa-chairs/novo/diagonal/novo-ivory-pearl.png'
import Novodiagonalivorygraphite from '../images/spa-chairs/novo/diagonal/novo-ivory-graphite.png'
import Novodiagonalivorymocha from '../images/spa-chairs/novo/diagonal/novo-ivory-mocha.png'

import Novodiagonalburgundyrosegold from '../images/spa-chairs/novo/diagonal/novo-burgundy-rosegold.png'
import Novodiagonalburgundygold from '../images/spa-chairs/novo/diagonal/novo-burgundy-gold.png'
import Novodiagonalburgundypearl from '../images/spa-chairs/novo/diagonal/novo-burgundy-pearl.png'
import Novodiagonalburgundygraphite from '../images/spa-chairs/novo/diagonal/novo-burgundy-graphite.png'
import Novodiagonalburgundymocha from '../images/spa-chairs/novo/diagonal/novo-burgundy-mocha.png'

import Novodiagonaldarkchocolaterosegold from '../images/spa-chairs/novo/diagonal/novo-darkchocolate-rosegold.png'
import Novodiagonaldarkchocolategold from '../images/spa-chairs/novo/diagonal/novo-darkchocolate-gold.png'
import Novodiagonaldarkchocolatepearl from '../images/spa-chairs/novo/diagonal/novo-darkchocolate-pearl.png'
import Novodiagonaldarkchocolategraphite from '../images/spa-chairs/novo/diagonal/novo-darkchocolate-graphite.png'
import Novodiagonaldarkchocolatemocha from '../images/spa-chairs/novo/diagonal/novo-darkchocolate-mocha.png'

// Tergo
import Tergodiagonalblackrosegold from '../images/spa-chairs/tergo/diagonal/tergo-black-rosegold.png'
import Tergodiagonalblackgold from '../images/spa-chairs/tergo/diagonal/tergo-black-gold.png'
import Tergodiagonalblackpearl from '../images/spa-chairs/tergo/diagonal/tergo-black-pearl.png'
import Tergodiagonalblackgraphite from '../images/spa-chairs/tergo/diagonal/tergo-black-graphite.png'
import Tergodiagonalblackmocha from '../images/spa-chairs/tergo/diagonal/tergo-black-mocha.png'

import Tergodiagonalfossilgreyrosegold from '../images/spa-chairs/tergo/diagonal/tergo-fossilgrey-rosegold.png'
import Tergodiagonalfossilgreygold from '../images/spa-chairs/tergo/diagonal/tergo-fossilgrey-gold.png'
import Tergodiagonalfossilgreypearl from '../images/spa-chairs/tergo/diagonal/tergo-fossilgrey-pearl.png'
import Tergodiagonalfossilgreygraphite from '../images/spa-chairs/tergo/diagonal/tergo-fossilgrey-graphite.png'
import Tergodiagonalfossilgreymocha from '../images/spa-chairs/tergo/diagonal/tergo-fossilgrey-mocha.png'

import Tergodiagonalcappuccinorosegold from '../images/spa-chairs/tergo/diagonal/tergo-cappuccino-rosegold.png'
import Tergodiagonalcappuccinogold from '../images/spa-chairs/tergo/diagonal/tergo-cappuccino-gold.png'
import Tergodiagonalcappuccinopearl from '../images/spa-chairs/tergo/diagonal/tergo-cappuccino-pearl.png'
import Tergodiagonalcappuccinographite from '../images/spa-chairs/tergo/diagonal/tergo-cappuccino-graphite.png'
import Tergodiagonalcappuccinomocha from '../images/spa-chairs/tergo/diagonal/tergo-cappuccino-mocha.png'

import Tergodiagonalivoryrosegold from '../images/spa-chairs/tergo/diagonal/tergo-ivory-rosegold.png'
import Tergodiagonalivorygold from '../images/spa-chairs/tergo/diagonal/tergo-ivory-gold.png'
import Tergodiagonalivorypearl from '../images/spa-chairs/tergo/diagonal/tergo-ivory-pearl.png'
import Tergodiagonalivorygraphite from '../images/spa-chairs/tergo/diagonal/tergo-ivory-graphite.png'
import Tergodiagonalivorymocha from '../images/spa-chairs/tergo/diagonal/tergo-ivory-mocha.png'

import Tergodiagonalburgundyrosegold from '../images/spa-chairs/tergo/diagonal/tergo-burgundy-rosegold.png'
import Tergodiagonalburgundygold from '../images/spa-chairs/tergo/diagonal/tergo-burgundy-gold.png'
import Tergodiagonalburgundypearl from '../images/spa-chairs/tergo/diagonal/tergo-burgundy-pearl.png'
import Tergodiagonalburgundygraphite from '../images/spa-chairs/tergo/diagonal/tergo-burgundy-graphite.png'
import Tergodiagonalburgundymocha from '../images/spa-chairs/tergo/diagonal/tergo-burgundy-mocha.png'

import Tergodiagonaldarkchocolaterosegold from '../images/spa-chairs/tergo/diagonal/tergo-darkchocolate-rosegold.png'
import Tergodiagonaldarkchocolategold from '../images/spa-chairs/tergo/diagonal/tergo-darkchocolate-gold.png'
import Tergodiagonaldarkchocolatepearl from '../images/spa-chairs/tergo/diagonal/tergo-darkchocolate-pearl.png'
import Tergodiagonaldarkchocolategraphite from '../images/spa-chairs/tergo/diagonal/tergo-darkchocolate-graphite.png'
import Tergodiagonaldarkchocolatemocha from '../images/spa-chairs/tergo/diagonal/tergo-darkchocolate-mocha.png'

// Magna
import Magnadiagonalblackrosegold from '../images/spa-chairs/magna/diagonal/magna-black-rosegold.png'
import Magnadiagonalblackgold from '../images/spa-chairs/magna/diagonal/magna-black-gold.png'
import Magnadiagonalblackpearl from '../images/spa-chairs/magna/diagonal/magna-black-pearl.png'
import Magnadiagonalblackgraphite from '../images/spa-chairs/magna/diagonal/magna-black-graphite.png'
import Magnadiagonalblackmocha from '../images/spa-chairs/magna/diagonal/magna-black-mocha.png'

import Magnadiagonalfossilgreyrosegold from '../images/spa-chairs/magna/diagonal/magna-fossilgrey-rosegold.png'
import Magnadiagonalfossilgreygold from '../images/spa-chairs/magna/diagonal/magna-fossilgrey-gold.png'
import Magnadiagonalfossilgreypearl from '../images/spa-chairs/magna/diagonal/magna-fossilgrey-pearl.png'
import Magnadiagonalfossilgreygraphite from '../images/spa-chairs/magna/diagonal/magna-fossilgrey-graphite.png'
import Magnadiagonalfossilgreymocha from '../images/spa-chairs/magna/diagonal/magna-fossilgrey-mocha.png'

import Magnadiagonalcappuccinorosegold from '../images/spa-chairs/magna/diagonal/magna-cappuccino-rosegold.png'
import Magnadiagonalcappuccinogold from '../images/spa-chairs/magna/diagonal/magna-cappuccino-gold.png'
import Magnadiagonalcappuccinopearl from '../images/spa-chairs/magna/diagonal/magna-cappuccino-pearl.png'
import Magnadiagonalcappuccinographite from '../images/spa-chairs/magna/diagonal/magna-cappuccino-graphite.png'
import Magnadiagonalcappuccinomocha from '../images/spa-chairs/magna/diagonal/magna-cappuccino-mocha.png'

import Magnadiagonalivoryrosegold from '../images/spa-chairs/magna/diagonal/magna-ivory-rosegold.png'
import Magnadiagonalivorygold from '../images/spa-chairs/magna/diagonal/magna-ivory-gold.png'
import Magnadiagonalivorypearl from '../images/spa-chairs/magna/diagonal/magna-ivory-pearl.png'
import Magnadiagonalivorygraphite from '../images/spa-chairs/magna/diagonal/magna-ivory-graphite.png'
import Magnadiagonalivorymocha from '../images/spa-chairs/magna/diagonal/magna-ivory-mocha.png'

import Magnadiagonalburgundyrosegold from '../images/spa-chairs/magna/diagonal/magna-burgundy-rosegold.png'
import Magnadiagonalburgundygold from '../images/spa-chairs/magna/diagonal/magna-burgundy-gold.png'
import Magnadiagonalburgundypearl from '../images/spa-chairs/magna/diagonal/magna-burgundy-pearl.png'
import Magnadiagonalburgundygraphite from '../images/spa-chairs/magna/diagonal/magna-burgundy-graphite.png'
import Magnadiagonalburgundymocha from '../images/spa-chairs/magna/diagonal/magna-burgundy-mocha.png'

import Magnadiagonaldarkchocolaterosegold from '../images/spa-chairs/magna/diagonal/magna-darkchocolate-rosegold.png'
import Magnadiagonaldarkchocolategold from '../images/spa-chairs/magna/diagonal/magna-darkchocolate-gold.png'
import Magnadiagonaldarkchocolatepearl from '../images/spa-chairs/magna/diagonal/magna-darkchocolate-pearl.png'
import Magnadiagonaldarkchocolategraphite from '../images/spa-chairs/magna/diagonal/magna-darkchocolate-graphite.png'
import Magnadiagonaldarkchocolatemocha from '../images/spa-chairs/magna/diagonal/magna-darkchocolate-mocha.png'

// Vsport
import Vsportdiagonalblackrosegold from '../images/spa-chairs/vsport/diagonal/vsport-black-rosegold.png'
import Vsportdiagonalblackgold from '../images/spa-chairs/vsport/diagonal/vsport-black-gold.png'
import Vsportdiagonalblackpearl from '../images/spa-chairs/vsport/diagonal/vsport-black-pearl.png'
import Vsportdiagonalblackgraphite from '../images/spa-chairs/vsport/diagonal/vsport-black-graphite.png'
import Vsportdiagonalblackmocha from '../images/spa-chairs/vsport/diagonal/vsport-black-mocha.png'

import Vsportdiagonalfossilgreyrosegold from '../images/spa-chairs/vsport/diagonal/vsport-fossilgrey-rosegold.png'
import Vsportdiagonalfossilgreygold from '../images/spa-chairs/vsport/diagonal/vsport-fossilgrey-gold.png'
import Vsportdiagonalfossilgreypearl from '../images/spa-chairs/vsport/diagonal/vsport-fossilgrey-pearl.png'
import Vsportdiagonalfossilgreygraphite from '../images/spa-chairs/vsport/diagonal/vsport-fossilgrey-graphite.png'
import Vsportdiagonalfossilgreymocha from '../images/spa-chairs/vsport/diagonal/vsport-fossilgrey-mocha.png'

import Vsportdiagonalcappuccinorosegold from '../images/spa-chairs/vsport/diagonal/vsport-cappuccino-rosegold.png'
import Vsportdiagonalcappuccinogold from '../images/spa-chairs/vsport/diagonal/vsport-cappuccino-gold.png'
import Vsportdiagonalcappuccinopearl from '../images/spa-chairs/vsport/diagonal/vsport-cappuccino-pearl.png'
import Vsportdiagonalcappuccinographite from '../images/spa-chairs/vsport/diagonal/vsport-cappuccino-graphite.png'
import Vsportdiagonalcappuccinomocha from '../images/spa-chairs/vsport/diagonal/vsport-cappuccino-mocha.png'

import Vsportdiagonalivoryrosegold from '../images/spa-chairs/vsport/diagonal/vsport-ivory-rosegold.png'
import Vsportdiagonalivorygold from '../images/spa-chairs/vsport/diagonal/vsport-ivory-gold.png'
import Vsportdiagonalivorypearl from '../images/spa-chairs/vsport/diagonal/vsport-ivory-pearl.png'
import Vsportdiagonalivorygraphite from '../images/spa-chairs/vsport/diagonal/vsport-ivory-graphite.png'
import Vsportdiagonalivorymocha from '../images/spa-chairs/vsport/diagonal/vsport-ivory-mocha.png'

import Vsportdiagonalburgundyrosegold from '../images/spa-chairs/vsport/diagonal/vsport-burgundy-rosegold.png'
import Vsportdiagonalburgundygold from '../images/spa-chairs/vsport/diagonal/vsport-burgundy-gold.png'
import Vsportdiagonalburgundypearl from '../images/spa-chairs/vsport/diagonal/vsport-burgundy-pearl.png'
import Vsportdiagonalburgundygraphite from '../images/spa-chairs/vsport/diagonal/vsport-burgundy-graphite.png'
import Vsportdiagonalburgundymocha from '../images/spa-chairs/vsport/diagonal/vsport-burgundy-mocha.png'

import Vsportdiagonaldarkchocolaterosegold from '../images/spa-chairs/vsport/diagonal/vsport-darkchocolate-rosegold.png'
import Vsportdiagonaldarkchocolategold from '../images/spa-chairs/vsport/diagonal/vsport-darkchocolate-gold.png'
import Vsportdiagonaldarkchocolatepearl from '../images/spa-chairs/vsport/diagonal/vsport-darkchocolate-pearl.png'
import Vsportdiagonaldarkchocolategraphite from '../images/spa-chairs/vsport/diagonal/vsport-darkchocolate-graphite.png'
import Vsportdiagonaldarkchocolatemocha from '../images/spa-chairs/vsport/diagonal/vsport-darkchocolate-mocha.png'

// // Vip
import Vipdiagonalblackrosegold from '../images/spa-chairs/vip/diagonal/vip-black-rosegoldwhite.png'
import Vipdiagonalblackgold from '../images/spa-chairs/vip/diagonal/vip-black-goldwhite.png'
import Vipdiagonalblackpearl from '../images/spa-chairs/vip/diagonal/vip-black-pearlwhite.png'
import Vipdiagonalblackgraphite from '../images/spa-chairs/vip/diagonal/vip-black-graphitewhite.png'
import Vipdiagonalblackmocha from '../images/spa-chairs/vip/diagonal/vip-black-mochawhite.png'

import Vipdiagonalfossilgreyrosegold from '../images/spa-chairs/vip/diagonal/vip-fossilgrey-rosegoldwhite.png'
import Vipdiagonalfossilgreygold from '../images/spa-chairs/vip/diagonal/vip-fossilgrey-goldwhite.png'
import Vipdiagonalfossilgreypearl from '../images/spa-chairs/vip/diagonal/vip-fossilgrey-pearlwhite.png'
import Vipdiagonalfossilgreygraphite from '../images/spa-chairs/vip/diagonal/vip-fossilgrey-graphitewhite.png'
import Vipdiagonalfossilgreymocha from '../images/spa-chairs/vip/diagonal/vip-fossilgrey-mochawhite.png'

import Vipdiagonalcappuccinorosegold from '../images/spa-chairs/vip/diagonal/vip-cappuccino-rosegoldwhite.png'
import Vipdiagonalcappuccinogold from '../images/spa-chairs/vip/diagonal/vip-cappuccino-goldwhite.png'
import Vipdiagonalcappuccinopearl from '../images/spa-chairs/vip/diagonal/vip-cappuccino-pearlwhite.png'
import Vipdiagonalcappuccinographite from '../images/spa-chairs/vip/diagonal/vip-cappuccino-graphitewhite.png'
import Vipdiagonalcappuccinomocha from '../images/spa-chairs/vip/diagonal/vip-cappuccino-mochawhite.png'

import Vipdiagonalivoryrosegold from '../images/spa-chairs/vip/diagonal/vip-ivory-rosegoldwhite.png'
import Vipdiagonalivorygold from '../images/spa-chairs/vip/diagonal/vip-ivory-goldwhite.png'
import Vipdiagonalivorypearl from '../images/spa-chairs/vip/diagonal/vip-ivory-pearlwhite.png'
import Vipdiagonalivorygraphite from '../images/spa-chairs/vip/diagonal/vip-ivory-graphitewhite.png'
import Vipdiagonalivorymocha from '../images/spa-chairs/vip/diagonal/vip-ivory-mochawhite.png'

import Vipdiagonalburgundyrosegold from '../images/spa-chairs/vip/diagonal/vip-burgundy-rosegoldwhite.png'
import Vipdiagonalburgundygold from '../images/spa-chairs/vip/diagonal/vip-burgundy-goldwhite.png'
import Vipdiagonalburgundypearl from '../images/spa-chairs/vip/diagonal/vip-burgundy-pearlwhite.png'
import Vipdiagonalburgundygraphite from '../images/spa-chairs/vip/diagonal/vip-burgundy-graphitewhite.png'
import Vipdiagonalburgundymocha from '../images/spa-chairs/vip/diagonal/vip-burgundy-mochawhite.png'

import Vipdiagonaldarkchocolaterosegold from '../images/spa-chairs/vip/diagonal/vip-darkchocolate-rosegoldwhite.png'
import Vipdiagonaldarkchocolategold from '../images/spa-chairs/vip/diagonal/vip-darkchocolate-goldwhite.png'
import Vipdiagonaldarkchocolatepearl from '../images/spa-chairs/vip/diagonal/vip-darkchocolate-pearlwhite.png'
import Vipdiagonaldarkchocolategraphite from '../images/spa-chairs/vip/diagonal/vip-darkchocolate-graphitewhite.png'
import Vipdiagonaldarkchocolatemocha from '../images/spa-chairs/vip/diagonal/vip-darkchocolate-mochawhite.png'

const chairs = {
  novo: {
    black_rosegold: Novodiagonalblackrosegold,
    black_gold: Novodiagonalblackgold,
    black_pearl: Novodiagonalblackpearl,
    black_graphite: Novodiagonalblackgraphite,
    black_mocha: Novodiagonalblackmocha,

    fossilgrey_rosegold: Novodiagonalfossilgreyrosegold,
    fossilgrey_gold: Novodiagonalfossilgreygold,
    fossilgrey_pearl: Novodiagonalfossilgreypearl,
    fossilgrey_graphite: Novodiagonalfossilgreygraphite,
    fossilgrey_mocha: Novodiagonalfossilgreymocha,

    cappuccino_rosegold: Novodiagonalcappuccinorosegold,
    cappuccino_gold: Novodiagonalcappuccinogold,
    cappuccino_pearl: Novodiagonalcappuccinopearl,
    cappuccino_graphite: Novodiagonalcappuccinographite,
    cappuccino_mocha: Novodiagonalcappuccinomocha,

    ivory_rosegold: Novodiagonalivoryrosegold,
    ivory_gold: Novodiagonalivorygold,
    ivory_pearl: Novodiagonalivorypearl,
    ivory_graphite: Novodiagonalivorygraphite,
    ivory_mocha: Novodiagonalivorymocha,

    burgundy_rosegold: Novodiagonalburgundyrosegold,
    burgundy_gold: Novodiagonalburgundygold,
    burgundy_pearl: Novodiagonalburgundypearl,
    burgundy_graphite: Novodiagonalburgundygraphite,
    burgundy_mocha: Novodiagonalburgundymocha,

    darkchocolate_rosegold: Novodiagonaldarkchocolaterosegold,
    darkchocolate_gold: Novodiagonaldarkchocolategold,
    darkchocolate_pearl: Novodiagonaldarkchocolatepearl,
    darkchocolate_graphite: Novodiagonaldarkchocolategraphite,
    darkchocolate_mocha: Novodiagonaldarkchocolatemocha
  },
  tergo: {
    black_rosegold: Tergodiagonalblackrosegold,
    black_gold: Tergodiagonalblackgold,
    black_pearl: Tergodiagonalblackpearl,
    black_graphite: Tergodiagonalblackgraphite,
    black_mocha: Tergodiagonalblackmocha,

    fossilgrey_rosegold: Tergodiagonalfossilgreyrosegold,
    fossilgrey_gold: Tergodiagonalfossilgreygold,
    fossilgrey_pearl: Tergodiagonalfossilgreypearl,
    fossilgrey_graphite: Tergodiagonalfossilgreygraphite,
    fossilgrey_mocha: Tergodiagonalfossilgreymocha,

    cappuccino_rosegold: Tergodiagonalcappuccinorosegold,
    cappuccino_gold: Tergodiagonalcappuccinogold,
    cappuccino_pearl: Tergodiagonalcappuccinopearl,
    cappuccino_graphite: Tergodiagonalcappuccinographite,
    cappuccino_mocha: Tergodiagonalcappuccinomocha,

    ivory_rosegold: Tergodiagonalivoryrosegold,
    ivory_gold: Tergodiagonalivorygold,
    ivory_pearl: Tergodiagonalivorypearl,
    ivory_graphite: Tergodiagonalivorygraphite,
    ivory_mocha: Tergodiagonalivorymocha,

    burgundy_rosegold: Tergodiagonalburgundyrosegold,
    burgundy_gold: Tergodiagonalburgundygold,
    burgundy_pearl: Tergodiagonalburgundypearl,
    burgundy_graphite: Tergodiagonalburgundygraphite,
    burgundy_mocha: Tergodiagonalburgundymocha,

    darkchocolate_rosegold: Tergodiagonaldarkchocolaterosegold,
    darkchocolate_gold: Tergodiagonaldarkchocolategold,
    darkchocolate_pearl: Tergodiagonaldarkchocolatepearl,
    darkchocolate_graphite: Tergodiagonaldarkchocolategraphite,
    darkchocolate_mocha: Tergodiagonaldarkchocolatemocha
  },
  magna: {
    black_rosegold: Magnadiagonalblackrosegold,
    black_gold: Magnadiagonalblackgold,
    black_pearl: Magnadiagonalblackpearl,
    black_graphite: Magnadiagonalblackgraphite,
    black_mocha: Magnadiagonalblackmocha,

    fossilgrey_rosegold: Magnadiagonalfossilgreyrosegold,
    fossilgrey_gold: Magnadiagonalfossilgreygold,
    fossilgrey_pearl: Magnadiagonalfossilgreypearl,
    fossilgrey_graphite: Magnadiagonalfossilgreygraphite,
    fossilgrey_mocha: Magnadiagonalfossilgreymocha,

    cappuccino_rosegold: Magnadiagonalcappuccinorosegold,
    cappuccino_gold: Magnadiagonalcappuccinogold,
    cappuccino_pearl: Magnadiagonalcappuccinopearl,
    cappuccino_graphite: Magnadiagonalcappuccinographite,
    cappuccino_mocha: Magnadiagonalcappuccinomocha,

    ivory_rosegold: Magnadiagonalivoryrosegold,
    ivory_gold: Magnadiagonalivorygold,
    ivory_pearl: Magnadiagonalivorypearl,
    ivory_graphite: Magnadiagonalivorygraphite,
    ivory_mocha: Magnadiagonalivorymocha,

    burgundy_rosegold: Magnadiagonalburgundyrosegold,
    burgundy_gold: Magnadiagonalburgundygold,
    burgundy_pearl: Magnadiagonalburgundypearl,
    burgundy_graphite: Magnadiagonalburgundygraphite,
    burgundy_mocha: Magnadiagonalburgundymocha,

    darkchocolate_rosegold: Magnadiagonaldarkchocolaterosegold,
    darkchocolate_gold: Magnadiagonaldarkchocolategold,
    darkchocolate_pearl: Magnadiagonaldarkchocolatepearl,
    darkchocolate_graphite: Magnadiagonaldarkchocolategraphite,
    darkchocolate_mocha: Magnadiagonaldarkchocolatemocha
  },
  vip: {
    black_rosegold: Vipdiagonalblackrosegold,
    black_gold: Vipdiagonalblackgold,
    black_pearl: Vipdiagonalblackpearl,
    black_graphite: Vipdiagonalblackgraphite,
    black_mocha: Vipdiagonalblackmocha,

    fossilgrey_rosegold: Vipdiagonalfossilgreyrosegold,
    fossilgrey_gold: Vipdiagonalfossilgreygold,
    fossilgrey_pearl: Vipdiagonalfossilgreypearl,
    fossilgrey_graphite: Vipdiagonalfossilgreygraphite,
    fossilgrey_mocha: Vipdiagonalfossilgreymocha,

    cappuccino_rosegold: Vipdiagonalcappuccinorosegold,
    cappuccino_gold: Vipdiagonalcappuccinogold,
    cappuccino_pearl: Vipdiagonalcappuccinopearl,
    cappuccino_graphite: Vipdiagonalcappuccinographite,
    cappuccino_mocha: Vipdiagonalcappuccinomocha,

    ivory_rosegold: Vipdiagonalivoryrosegold,
    ivory_gold: Vipdiagonalivorygold,
    ivory_pearl: Vipdiagonalivorypearl,
    ivory_graphite: Vipdiagonalivorygraphite,
    ivory_mocha: Vipdiagonalivorymocha,

    burgundy_rosegold: Vipdiagonalburgundyrosegold,
    burgundy_gold: Vipdiagonalburgundygold,
    burgundy_pearl: Vipdiagonalburgundypearl,
    burgundy_graphite: Vipdiagonalburgundygraphite,
    burgundy_mocha: Vipdiagonalburgundymocha,

    darkchocolate_rosegold: Vipdiagonaldarkchocolaterosegold,
    darkchocolate_gold: Vipdiagonaldarkchocolategold,
    darkchocolate_pearl: Vipdiagonaldarkchocolatepearl,
    darkchocolate_graphite: Vipdiagonaldarkchocolategraphite,
    darkchocolate_mocha: Vipdiagonaldarkchocolatemocha
  },
  vsport: {
    black_rosegold: Vsportdiagonalblackrosegold,
    black_gold: Vsportdiagonalblackgold,
    black_pearl: Vsportdiagonalblackpearl,
    black_graphite: Vsportdiagonalblackgraphite,
    black_mocha: Vsportdiagonalblackmocha,

    fossilgrey_rosegold: Vsportdiagonalfossilgreyrosegold,
    fossilgrey_gold: Vsportdiagonalfossilgreygold,
    fossilgrey_pearl: Vsportdiagonalfossilgreypearl,
    fossilgrey_graphite: Vsportdiagonalfossilgreygraphite,
    fossilgrey_mocha: Vsportdiagonalfossilgreymocha,

    cappuccino_rosegold: Vsportdiagonalcappuccinorosegold,
    cappuccino_gold: Vsportdiagonalcappuccinogold,
    cappuccino_pearl: Vsportdiagonalcappuccinopearl,
    cappuccino_graphite: Vsportdiagonalcappuccinographite,
    cappuccino_mocha: Vsportdiagonalcappuccinomocha,

    ivory_rosegold: Vsportdiagonalivoryrosegold,
    ivory_gold: Vsportdiagonalivorygold,
    ivory_pearl: Vsportdiagonalivorypearl,
    ivory_graphite: Vsportdiagonalivorygraphite,
    ivory_mocha: Vsportdiagonalivorymocha,

    burgundy_rosegold: Vsportdiagonalburgundyrosegold,
    burgundy_gold: Vsportdiagonalburgundygold,
    burgundy_pearl: Vsportdiagonalburgundypearl,
    burgundy_graphite: Vsportdiagonalburgundygraphite,
    burgundy_mocha: Vsportdiagonalburgundymocha,

    darkchocolate_rosegold: Vsportdiagonaldarkchocolaterosegold,
    darkchocolate_gold: Vsportdiagonaldarkchocolategold,
    darkchocolate_pearl: Vsportdiagonaldarkchocolatepearl,
    darkchocolate_graphite: Vsportdiagonaldarkchocolategraphite,
    darkchocolate_mocha: Vsportdiagonaldarkchocolatemocha
  }
}
const chairColorBtns = ['black', 'fossilgrey', 'cappuccino', 'ivory', 'burgundy', 'darkchocolate']
const bowls = [
  { color: 'rosegold', src: rosegoldBowl },
  { color: 'gold', src: goldBowl },
  { color: 'pearl', src: pearlBowl },
  { color: 'graphite', src: graphiteBowl },
  { color: 'mocha', src: mochaBowl }
]

const CustomChairDisplay = ({ model, price, defaultChairColor, defaultBowlColor }) => {
  const [currentChairColor, setCurrentChairColor] = React.useState(defaultChairColor)
  const [currentBowlColor, setCurrentBowlColor] = React.useState(defaultBowlColor)

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7 d-flex flex-column align-items-center animated bounceInLeft slow py-4 px-5">
          <img
            src={chairs[`${model}`][`${currentChairColor}_${currentBowlColor}`]}
            alt="novo chair"
            className="img-fluid chair-img"
          />
          <h6 className="text-secondary my-4">Digital Coloring May Vary</h6>
        </div>
        <div className="col-lg-5 d-flex flex-column justify-content-center animated bounceInRight slow px-4">
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="m-0 pr-4">
              <strong>Chair </strong>
            </h5>
            {chairColorBtns.map(chairColor => {
              return (
                <button
                  aria-label="chair color button"
                  type="button"
                  key={chairColor}
                  onClick={() => {
                    setCurrentChairColor(chairColor)
                  }}
                  className={classNames(`${chairColor}`, 'rounded-circle mr-2 mb-1 color-btn', {
                    'animated infinite pulse': currentChairColor === chairColor
                  })}
                />
              )
            })}
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <h5 className="m-0 pr-4">
              <strong>Bowl </strong>
            </h5>
            {bowls.map(bowl => {
              return (
                <div
                  key={bowl.color}
                  tabIndex="0"
                  role="button"
                  onClick={() => setCurrentBowlColor(bowl.color)}
                  onKeyPress={e => {
                    if (e.key === 'enter') {
                      setCurrentBowlColor(bowl.color)
                    }
                  }}
                >
                  <img
                    src={bowl.src}
                    alt={`${bowl.color} bowl`}
                    className={classNames('rounded-circle mr-2 mb-1 bowl-img', {
                      'animated infinite pulse': currentBowlColor === bowl.color
                    })}
                  />
                </div>
              )
            })}
          </div>
          <div className="w-100 mt-4">
            <h2 className="text-uppercase">
              {model} {currentChairColor} {currentBowlColor}
            </h2>
            <p>$ {price}</p>
            <a href="tel:7148958865" className="action-button py-2 px-4 rounded-pill">
              Call for Estimate
            </a>
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}
export default CustomChairDisplay
