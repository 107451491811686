import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import ChairModelNavBar from '../../components/ChairModelNavBar'
import CustomChairDisplay from '../../components/CustomChairDisplay'
import ChairAccessories from '../../components/ChairAccessories'
import PedicureSpaChairSpecs from '../../components/PedicureSpaChairSpecs'
import ChairFeatures from '../../components/ChairFeatures'

import VSportBanner from '../../images/general/vsport-banner.jpg'
import VSportLogo from '../../images/logos/vsport-logo-compact.png'

const VSportPage = () => (
  <Layout>
    <SEO
      title="VSPORT Pedicure Spa Chair"
      description="CONTÉGO VSPORT Spa Chair Series with V stitch leather brings a sporty look into your salon while equipped with the most advanced and patented CONTÉGO technologies. CALL US at (714) 895-8865 to know more about VSPORT Series."
      meta={[
        {
          name: 'keywords',
          content:
            'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
        }
      ]}
    />
    <ChairModelNavBar />

    <div className="row shadow mt-4">
      <div className="col-lg-7 px-0 animated fadeInLeft slow">
        <img src={VSportBanner} alt="pedicure spa chair banner" className="img-fluid" />
      </div>
      <div className="col-lg-5 px-5 d-flex flex-column align-items-center justify-content-center py-4 animated fadeInRight slow">
        <img src={VSportLogo} alt="pedicure spa chair logo" className="img-fluid" />
      </div>
    </div>

    <div className="container-fluid py-5 px-0">
      <CustomChairDisplay
        model="vsport"
        des="So feel been kept be at gate. Be september it extensive oh concluded of certainty. In read most gate at body held it ever no. Talking justice welcome message inquiry in started of am me."
        price="3,395"
        defaultChairColor="ivory"
        defaultBowlColor="gold"
      />
    </div>
    <div className="container-fluid py-2 shadow">
      <ChairAccessories
        model="pedistool"
        name="Pedi Stool"
        des="Maids table how learn drift but purse stand yet set. Music me house could among oh as their. Piqued our sister shy nature almost his wicket. Hand dear so we hour to. He we be hastily offence effects he service."
        defaultChairColor="ivory"
        defaultTexture="diamond"
        price="185"
      />
    </div>
    <ChairFeatures />
    <div className="container-fluid py-4">
      <PedicureSpaChairSpecs />
    </div>
  </Layout>
)

export default VSportPage
